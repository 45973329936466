import { useEffect, useState } from "react";
import cnx from "../service/Servicos";
import Skeleton from "@material-ui/lab/Skeleton";
import TabsFechas from "./TabsFechasComponents";

const qs = require("qs");

const query = (id, id_gestion) => {
  return qs.stringify(
    {
      filters: {
        categorias_publica: {
          id: {
            $eq: id,
          },
        },
        gestione: {
          id: {
            $eq: id_gestion,
          },
        },
      },
      populate: "*",
      sort: ["createdAt:desc"],
      pagination: {
        pageZise: 100,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
};

const queryTransArchivo = (id) => {
  return qs.stringify(
    {
      filters: {
        transparencias_publica: {
          id: {
            $eq: id,
          },
        },
      },
      populate: "archivos_publicos.archivo",
      pagination: {
        pageSize: 100,
      },
      sort: ["createdAt:desc"],
    },
    {
      encodeValuesOnly: true,
    }
  );
};

const id = 2;
let ges = [];
export default function ConvocatoriasComponent() {
  const [cargando, setCargando] = useState(true);

  const [titulo, setTitulo] = useState("");
  const [datos, setDatos] = useState([]);
  const [items, setItems] = useState([]);
  const [idCategoria, setIdCategoria] = useState(null);
  const [idGestion, setIdGestion] = useState(18);
  const [gestiones, setGestiones] = useState([]);

  useEffect(() => {
    try {
      const obtenerGestiones = async () => {
        const res = await cnx.obtenerDatos(`api/gestiones?sort[0]=id%3Adesc`);
        if (res.length > 0) {
           const g = res.map(g => {
               if(g.id >= 17 ){
                    ges.push(g);
               }
           });
          setGestiones(ges);
          // setIdGestion(res.data.data);
        }
      };
      obtenerGestiones();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const obtenerTitulo = async () => {
      const res = await cnx.obtenerDatos(`api/categorias-publicas/${id}`);
      if (res) {
        const {
          id,
          attributes: { titulo },
        } = res;
        setTitulo(titulo);
        setIdCategoria(id);
      }
    };
    obtenerTitulo();
  }, []);

  useEffect(() => {
    if (idCategoria !== null) {
      const obtenerTrasnparencias = async () => {
        setCargando(true);
        // http://10.10.54.9:1337/api/transparencias-publicas?filters[categorias_publica][id][$eq]=1&filters[gestione][id]=18&populate=*&pagination[pageSize]=100
        const res = await cnx.obtenerDatos(
          `api/transparencias-publicas?${query(idCategoria, idGestion)}`
        );
        if (res) {
          const transparencia = res.map((y) => {
            const {
              id,
              attributes: {
                titulo: sub,
                fecha_fin,
                fecha_inicio,
                mostrar_fin,
                mostrar_fini,
                orden,
                transparencias_archivos,
              },
            } = y;
            const id_archivos = transparencias_archivos.data.map((z) => z.id);
            id_archivos.sort((a, b) => {
                if(a === b) {
                  return 0; 
                }
                if(a < b) {
                  return -1;
                }
                return 1;
              });
            console.log({ id_archivos });
            return {
              id,
              sub,
              fecha_fin,
              fecha_inicio,
              mostrar_fin,
              mostrar_fini,
              orden,
              id_archivos,
              valor: false,
            };
          });
          console.log(transparencia);
          setDatos(transparencia);
          setCargando(false);
        }else{
          setCargando(false);
        }
      };
      obtenerTrasnparencias();
    }
  }, [idCategoria, idGestion]);

  useEffect(() => {
    if (datos.length > 0) {
      const obtenerArchivos = async () => {
        let promesas = [];
        for (const x of datos) {
          console.log(x.id);
          promesas.push(
            cnx.obtenerDatos(
              `api/transparencias-archivos?${queryTransArchivo(x.id)}`
            )
          );
        }
        const res = await Promise.all(promesas);
        console.log(res);
        const newItems = res.map((items, i) => {
          // const {attributes : {archivos_publicos : [data]}} = ;
          const mapeo = items.map((x) => {
            const {
              id,
              attributes: {
                archivos_publicos: { data: dt },
              },
            } = x;
            const {
              attributes: {
                archivo: { data: d },
                titulo,
                orden,
              },
            } = dt[0];
            const {
              attributes: { url, name },
            } = d[0];
            // console.log(archivos_publicos);
            console.log(url);
            return { id, url, name, titulo, orden };
          });

          console.log(mapeo);
          return mapeo;
        });
        console.log(newItems);
        setItems(newItems);
      };
      obtenerArchivos();
    }
  }, [datos]);

  // const handleChange = (event, value) => {
  //   setIdGestion(value);
  // };

  const handleClick = (valor, i) => {
    const list = cerrarAcordion();
    if (valor === false) list[i]["valor"] = true;
    setDatos(list);
  };

  const cerrarAcordion = () => {
    const list = [...datos];
    return list.map((x) => ({ ...x, valor: false }));
  };

  if (cargando) {
    return (
      <div className="container">
        <h2 id="titulo">{titulo}</h2>
        <div className="mb-2">
        <TabsFechas gestiones={gestiones} setIdGestion={setIdGestion} idGestion={idGestion} />
      </div>
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
      </div>
    );
  }

  return (
    <div className="">
      <h2 id="titulo">{titulo}</h2>
      <div className="mb-2">
        <TabsFechas gestiones={gestiones} setIdGestion={setIdGestion} idGestion={idGestion} />
      </div>
      <div className="accordion">
        {datos.map((x, i) => (
          <div className="accordion-item" key={i}>
            <button
              id="accordion-button-1"
              aria-expanded={x.valor}
              onClick={(e) => handleClick(x.valor, i)}
            >
              <span className="accordion-title">{x.sub}</span>
              <span className="icon" aria-hidden="true"></span>
            </button>
            <div className="accordion-content">
              {x.mostrar_fini === true ? (
                <p id="inicio" className="parrafo">
                  Fecha publicación:{" "}
                  <span>
                    {" "}
                    {x.fecha_inicio === null ? "S/N" : x.fecha_inicio}{" "}
                  </span>
                </p>
              ) : (
                ""
              )}
              {x.mostrar_fin === true ? (
                <p id="fin" className="parrafo">
                  Fecha de Fin :{" "}
                  <span> {x.fecha_fin === null ? "S/N" : x.fecha_fin}</span>
                </p>
              ) : (
                ""
              )}

              {x.id_archivos.length > 0
                ? x.id_archivos.map((z) => {
                    return items.map((y) => {
                      return y.map((d, index) => {
                        if (d.id === z) {
                          return (
                            <>
                              <a
                                id="enlace"
                                key={d.id}
                                className="convocatoria"
                                href={`${cnx.url}${d.url}`}
                                target="_blank"
                                rel="noreferrer"
                                download={d.name}
                              >
                                {y.length - index}&#41; {d.titulo}
                              </a>
                              <br />
                            </>
                          );
                        }
                      });
                    });
                  })
                : ""}
            </div>
          </div>
        ))}

        {datos.length > 0 ? (
          ""
        ) : (
          <>
            {/* <div class="isa_info">Replace this text with your own INFO text.</div> */}

            {/* <div class="isa_success">Replace this text with your own SUCCESS text.</div> */}

            <div class="isa_warning">NO HAY DATOS</div>

            {/* <div class="isa_error">Replace this text with your own ERROR text.</div> */}
          </>
        )}
      </div>
    </div>
  );
}
